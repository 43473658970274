/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import dayjs from 'dayjs';
import { Tag, Popover, Button } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import { faShareSquare } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { fbButton, linkedin, pinterest, tw, whatsapp } from 'vanilla-sharing';
import isEmpty from 'lodash.isempty';

import { isColorLight } from '~/utils/colors';
import usePropertyInView from '~/utils/usePropertyInView';

// import AgentPopover from '~/components/AgentPopover';
import AgentContactNumber from '~/components/AgentContactNumber';
import MainFeatures from '~/components/MainFeatures';
import PropertyAddress from '~/components/PropertyAddress';
import PropertyCarousel from '~/components/PropertyCarousel';
import PropertyPriceParse from '~/components/PropertyPriceParse';
import AgentWithLive from '~/components/AgentWithLive';
import HideListing from '~/components/HideListing';

import facebookIcon from '~/assets/social/facebook.png';
import linkedinIcon from '~/assets/social/linkedin.png';
import pinterestIcon from '~/assets/social/pinterest.png';
import twitterIcon from '~/assets/social/twitter.png';
import whatsappIcon from '~/assets/social/whatsapp.png';

import styles from './FeaturedPropertyCardSm.module.scss';

const FeaturedPropertyCardSm = ({
    offsetHeaderHeight,
    property,
    ofiTime,
    isFromSearch,
    hasScrolled,
    searchOptions,
    searchUrl,
    propertySearchVariables,
    isPropertyUpgradePage,
}) => {
    const {
        id,
        features,
        featuredTier,
        description,
        objects,
        mainAgent,
        priceView,
        isUnderOffer,
        status,
        agency,
        slugUrl,
        rentDateAvailable,
    } = property;

    const [isLight, setIsLight] = useState(false);

    let formatRentDateAvailable;
    const card = useRef(null);
    const isPropertyInView = usePropertyInView({
        offsetHeaderHeight,
        hasScrolled,
        property,
        card: card.current,
    });
    const { address, name, miniLogoUrl, bannerColor, logoUrl, slugUrl: agencySlugUrl } = agency || {};
    const propertyStatus = (status || 'current').toUpperCase();
    const { firstName, lastName, profilePicture } = mainAgent || {};
    const url = `https://www.realty.com.au/property/${slugUrl}`;
    const withLiveStyle =
        'absolute bottom-0 ml-3 text-white text-xs px-4 rounded-sm border border-white bg-primary-500';

    const currentDate = dayjs();

    if (rentDateAvailable) {
        formatRentDateAvailable = dayjs(rentDateAvailable);
    }

    useEffect(() => {
        if (bannerColor) {
            setIsLight(isColorLight(bannerColor));
        }
    }, [bannerColor]);

    return (
        <div className="w-full" ref={card}>
            <div
                className={`${styles.FeaturedPropertyCardSm} relative flex w-full flex-col overflow-hidden rounded-md bg-white`}
            >
                <div className="relative h-16 w-full p-1 text-right" style={{ background: bannerColor || '#2F3940' }}>
                    {/* <div className={`${mainAgent} absolute text-center z-20 flex pl-2`}>
                        <AgentPopover property={property} />
                    </div> */}
                    {mainAgent && (
                        <div className="flex w-full">
                            <div className="absolute z-20 flex pl-2">
                                <div>
                                    <Link
                                        href={`/agent/${mainAgent.slugUrl}`}
                                        className="text-gray-800 no-underline focus:no-underline active:no-underline"
                                    >
                                        <AgentWithLive
                                            key={mainAgent.id}
                                            agent={mainAgent}
                                            propertyId={property?.id ?? null}
                                            bannerColor={agency?.bannerColor ?? null}
                                            withLiveStyle={withLiveStyle}
                                            style={{
                                                borderColor: bannerColor || '#2F3940',
                                                background: bannerColor || '#2F3940',
                                            }}
                                            mainAgent
                                        />
                                    </Link>
                                </div>
                                <div className="ml-4 flex flex-col items-start">
                                    <div className={`${mainAgent.contactNo ? 'mt-0' : 'mt-2'}`}>
                                        <Link
                                            href={`/agent/${mainAgent.slugUrl}`}
                                            className={`${
                                                isLight ? 'text-slate-900' : 'text-white'
                                            } text-sm font-semibold no-underline focus:no-underline active:no-underline`}
                                        >
                                            {firstName} {lastName}
                                        </Link>
                                    </div>
                                    {mainAgent.contactNo && (
                                        <div className="font-medium">
                                            <PhoneOutlined
                                                style={{ color: isLight ? 'black' : 'white' }}
                                                className=" mr-1 text-sm"
                                            />
                                            <AgentContactNumber
                                                agentId={mainAgent.id}
                                                contactNo={mainAgent.contactNo}
                                                propertyId={id}
                                                className={`${isLight ? 'text-slate-900' : 'text-white'} text-sm`}
                                                useTel
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={`hidden h-full sm:inline-block  ${isPropertyUpgradePage ? 'w-2/5' : 'w-1/2'}`}>
                        <Link href={`/agency/${agencySlugUrl}`}>
                            <img
                                src={miniLogoUrl || logoUrl}
                                alt={name}
                                className="h-full cursor-pointer object-contain"
                            />
                        </Link>
                    </div>
                </div>
                {/* <Col xs={24} className="h-16 relative text-right p-1" style={{ background: bannerColor || '#2F3940' }}> */}
                {/*    <div className="max-w-max h-full inline-block"> */}
                {/*        <img src={miniLogoUrl || logoUrl} alt={name} className="cover-img" /> */}
                {/*    </div> */}
                {/* </Col> */}
                <div className="w-full border-b-4" style={{ borderColor: bannerColor || '#2F3940' }}>
                    <div className="relative w-full">
                        <PropertyCarousel
                            property={property}
                            ofiTime={ofiTime}
                            height={featuredTier === 'PREMIUM' ? '20rem' : '16rem'}
                            isFromSearch={isFromSearch}
                            searchOptions={searchOptions || null}
                            searchUrl={searchUrl}
                            isPropertyInView={isPropertyInView}
                            ratio="75%"
                            isFromFeaturedPropertyCard={!isEmpty(mainAgent)}
                        />
                    </div>
                </div>
                <div className="relative flex w-full justify-between self-center p-4">
                    <div className="w-full md:w-2/3">
                        <h4 className="text-overflow flex w-full items-center text-left text-lg font-semibold text-gray-800 md:w-5/6">
                            {isUnderOffer && <Tag className="text-xs">Under offer</Tag>}
                            <PropertyPriceParse
                                property={property}
                                rentBondClass="text-xs text-gray-700"
                                className="text-left"
                            />
                        </h4>
                        <div className="text-overflow mb-1 text-sm font-semibold leading-loose">
                            <a className="property-address text-gray-700">
                                <PropertyAddress
                                    property={property}
                                    newLine
                                    query={{
                                        isFromSearch,
                                        searchOptions: searchOptions || null,
                                        searchUrl,
                                    }}
                                />
                            </a>
                        </div>
                        {/* rental date available */}
                        {formatRentDateAvailable ? (
                            formatRentDateAvailable <= currentDate ? (
                                <div className="mb-2 text-xs font-light">Available Now</div>
                            ) : (
                                <div className="mb-2 text-xs font-light">
                                    Available {dayjs(formatRentDateAvailable).format('DD MMM YYYY')}
                                </div>
                            )
                        ) : null}
                        <div className="mb-1">
                            <MainFeatures
                                features={features}
                                displayText
                                classNames={{
                                    feature: `rounded-full ${styles.propertyFeature} mr-2 text-gray-600 font-semibold text-base`,
                                }}
                            />
                        </div>
                        <div className={`${styles.shareButton} flex flex-wrap items-center justify-between p-2`}>
                            <div className="pr-4 text-blue-400">
                                <Popover
                                    trigger="click"
                                    placement="topLeft"
                                    content={
                                        <div className="inline-block">
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() => fbButton({ url })}
                                            >
                                                <img
                                                    src={facebookIcon}
                                                    alt="facebook"
                                                    height="45"
                                                    width="45"
                                                    className="mr-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    tw({
                                                        url,
                                                        title: address,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={twitterIcon}
                                                    alt="twitter"
                                                    height="45"
                                                    width="45"
                                                    className="mx-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    whatsapp({
                                                        url,
                                                        title: address,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={whatsappIcon}
                                                    alt="whatsapp"
                                                    height="45"
                                                    width="45"
                                                    className="mx-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    linkedin({
                                                        url,
                                                        address,
                                                        description,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={linkedinIcon}
                                                    alt="linkedin"
                                                    height="45"
                                                    width="45"
                                                    className="mx-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    pinterest({
                                                        url,
                                                        description,
                                                        media: objects[0].url,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={pinterestIcon}
                                                    alt="pinterest"
                                                    height="45"
                                                    width="45"
                                                    className="ml-2"
                                                />
                                            </a>
                                        </div>
                                    }
                                >
                                    <Button
                                        type="text"
                                        icon={
                                            <FA
                                                icon={faShareSquare}
                                                style={{ fontSize: '1.125rem' }}
                                                className="mr-2 text-lg sm:text-xl"
                                            />
                                        }
                                        className="px-0 text-lg font-light text-gray-600 hover:bg-transparent hover:text-primary-500 sm:text-base md:text-lg"
                                    >
                                        <span className="text-base">Share</span>
                                    </Button>
                                </Popover>
                            </div>
                            <div>
                                <HideListing
                                    hideStatus="unhide"
                                    propertyId={id}
                                    propertySearchVariables={propertySearchVariables}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FeaturedPropertyCardSm.propTypes = {
    property: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    ofiTime: PropTypes.bool,
    isFromSearch: PropTypes.bool,
    hasScrolled: PropTypes.bool,
    offsetHeaderHeight: PropTypes.number,
    searchUrl: PropTypes.string,
    searchOptions: PropTypes.string,
    propertySearchVariables: PropTypes.object,
    isPropertyUpgradePage: PropTypes.bool,
};

FeaturedPropertyCardSm.defaultProps = {
    property: {},
    ofiTime: false,
    isFromSearch: false,
    hasScrolled: false,
    offsetHeaderHeight: 0,
    searchUrl: '',
    searchOptions: '',
    propertySearchVariables: {},
    isPropertyUpgradePage: false,
};

export default FeaturedPropertyCardSm;
