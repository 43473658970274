/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tag, Popover, Button } from 'antd';
import clsx from 'clsx';
import Color from 'color';
import { fbButton, linkedin, pinterest, tw, whatsapp } from 'vanilla-sharing';
import { PhoneOutlined } from '@ant-design/icons';
import { faShareSquare } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import dayjs from 'dayjs';
import isEmpty from 'lodash.isempty';

import usePropertyInView from '~/utils/usePropertyInView';
import { isColorLight } from '~/utils/colors';

import MainFeatures from '~/components/MainFeatures';
import PropertyAddress from '~/components/PropertyAddress';
import PropertyCarousel from '~/components/PropertyCarousel';
import PropertyPriceParse from '~/components/PropertyPriceParse';
import AgentWithLive from '~/components/AgentWithLive';
import HideListing from '~/components/HideListing';
import AgentContactNumber from '~/components/AgentContactNumber';

import facebookIcon from '~/assets/social/facebook.png';
import linkedinIcon from '~/assets/social/linkedin.png';
import pinterestIcon from '~/assets/social/pinterest.png';
import twitterIcon from '~/assets/social/twitter.png';
import whatsappIcon from '~/assets/social/whatsapp.png';

import styles from './FeaturedPropertyCard.module.scss';

const FeaturedPropertyCard = ({
    offsetHeaderHeight,
    property,
    ofiTime,
    isFromSearch,
    hasScrolled,
    collectionId,
    isFeed,
    sr,
    carouselImageHeight,
    isHomePage,
    showCardActions,
    isInfoBox,
    isPropertyPage,
    searchOptions,
    searchUrl,
    propertySearchVariables,
}) => {
    const {
        id,
        features,
        description,
        objects,
        mainAgent,
        priceView,
        isUnderOffer,
        status,
        slugUrl,
        agency,
        rentDateAvailable,
    } = property || {};

    const [isLight, setIsLight] = useState(false);

    let formatRentDateAvailable;
    let convertToMomentRentDate;
    const card = useRef(null);
    const isPropertyInView = usePropertyInView({
        offsetHeaderHeight,
        hasScrolled,
        property,
        card: card.current,
    });

    const { address, name, miniLogoUrl, bannerColor, logoUrl, slugUrl: agencySlugUrl } = agency || {};
    const propertyStatus = (status || 'current').toUpperCase();
    const { firstName, lastName, profilePicture } = mainAgent || {};
    const url = `https://www.realty.com.au/property/${slugUrl}`;
    const withLiveStyle =
        'absolute bottom-0 ml-3 text-white text-xs px-4 rounded-sm border border-white bg-primary-600';

    const currentDate = dayjs();

    if (rentDateAvailable) {
        formatRentDateAvailable = dayjs(rentDateAvailable);
    }

    useEffect(() => {
        if (bannerColor) {
            setIsLight(isColorLight(bannerColor));
        }
    }, [bannerColor]);

    return (
        <div className="h-full w-full" ref={card}>
            <div
                className={clsx(`${styles.FeaturedPropertyCard} relative h-full w-full overflow-hidden bg-white`, {
                    'rounded-md': !isFeed,
                })}
                key={id}
            >
                <div
                    className="relative flex h-16 items-center p-1 px-2 text-right"
                    style={{ background: bannerColor || '#2F3940' }}
                >
                    {mainAgent && (
                        <div className="flex w-1/2">
                            <div className="z-20 flex items-center text-center">
                                <div className="mt-12">
                                    <Link
                                        href={`/agent/${mainAgent?.slugUrl}`}
                                        className="mt-12 text-gray-800 no-underline focus:no-underline active:no-underline"
                                    >
                                        {/* eslint-disable-next-line max-len */}

                                        <AgentWithLive
                                            key={mainAgent?.id}
                                            agent={mainAgent}
                                            propertyId={property?.id ?? null}
                                            bannerColor={agency?.bannerColor ?? null}
                                            withLiveStyle={withLiveStyle}
                                            style={{
                                                borderColor: bannerColor || '#2F3940',
                                                background: bannerColor || '#2F3940',
                                            }}
                                            isFromFeaturedPropertyCard
                                            mainAgent
                                        />
                                    </Link>
                                </div>
                                <div className="ml-2 flex flex-col items-start justify-center pt-2">
                                    <Link
                                        href={`/agent/${mainAgent?.slugUrl}`}
                                        className={clsx(
                                            // eslint-disable-next-line max-len
                                            'pr-2 text-lg font-semibold no-underline focus:no-underline active:no-underline',
                                            {
                                                'text-black': isLight,
                                                'text-white': !isLight,
                                            }
                                        )}
                                    >
                                        {firstName} {lastName}
                                    </Link>
                                    {mainAgent?.contactNo ? (
                                        <div className="">
                                            <PhoneOutlined
                                                style={{ color: isLight ? 'black' : 'white' }}
                                                className="mr-1 text-sm"
                                            />

                                            <AgentContactNumber
                                                agentId={mainAgent?.id}
                                                contactNo={mainAgent?.contactNo}
                                                propertyId={id}
                                                className={`${
                                                    Color(bannerColor).isLight() ? 'text-black' : 'text-white'
                                                } text-sm`}
                                                useTel
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="inline-block h-full w-1/2 max-w-max ">
                        <Link href={`/agency/${agencySlugUrl}`}>
                            <img
                                src={miniLogoUrl || logoUrl}
                                alt={name}
                                className={`cursor-pointer ${miniLogoUrl || logoUrl ? 'h-full object-contain ' : ' '} `}
                            />
                        </Link>
                    </div>
                </div>
                <div className="flex">
                    <div className="relative w-1/2 cursor-pointer overflow-hidden">
                        <PropertyCarousel
                            collectionId={collectionId}
                            property={property}
                            ofiTime={ofiTime}
                            height={carouselImageHeight}
                            isFromSearch={isFromSearch}
                            searchOptions={searchOptions || null}
                            searchUrl={searchUrl}
                            sr={sr}
                            isHomePage={isHomePage}
                            isPropertyInView={isPropertyInView}
                            showActions={showCardActions}
                            isInfoBox={isInfoBox}
                            ratio="100%"
                            isFromFeaturedPropertyCard={!isEmpty(mainAgent)}
                        />
                    </div>

                    <div className="property-card-info relative flex w-1/2 flex-col justify-between p-3 sm:p-3">
                        <div className="w-full p-2 md:p-3 lg:p-5">
                            {/* eslint-disable-next-line max-len */}
                            <h4 className="text-overflow flex items-center text-left text-2xl font-medium text-gray-800">
                                <div className={`flex-1 ${isPropertyPage ? 'break-word' : 'text-overflow'}`}>
                                    <PropertyPriceParse
                                        className="text-left"
                                        property={property}
                                        rentBondClass="text-xs text-gray-700"
                                        isPropertyPage={isPropertyPage}
                                    />
                                </div>
                            </h4>

                            {/* eslint-disable-next-line max-len */}
                            <div className="text-overflow my-2 text-base font-semibold leading-loose text-gray-700 xl:text-lg">
                                <PropertyAddress
                                    property={property}
                                    newLine
                                    query={{
                                        isFromSearch,
                                        sr,
                                        searchOptions: searchOptions || null,
                                        searchUrl,
                                    }}
                                    isPropertyPage={isPropertyPage}
                                />
                            </div>
                            {/* rental date available */}
                            {formatRentDateAvailable ? (
                                formatRentDateAvailable <= currentDate ? (
                                    <div className="mb-2 text-sm font-light">Available Now</div>
                                ) : (
                                    <div className="mb-2 text-sm font-light">
                                        Available {dayjs(formatRentDateAvailable).format('DD MMM YYYY')}
                                    </div>
                                )
                            ) : null}
                            <div className="mb-2 text-left">
                                <MainFeatures
                                    features={features}
                                    displayText={!isPropertyPage}
                                    classNames={{
                                        // eslint-disable-next-line max-len
                                        feature: `rounded-full ${styles.propertyFeature} mr-2 text-gray-600 font-semibold text-xl`,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center justify-between p-2 md:p-3 lg:p-5">
                            <div className="pr-4 text-blue-400">
                                <Popover
                                    trigger="click"
                                    placement="topLeft"
                                    content={
                                        <div className="inline-block">
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() => fbButton({ url })}
                                            >
                                                <img
                                                    src={facebookIcon}
                                                    alt="facebook"
                                                    height="45"
                                                    width="45"
                                                    className="mr-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    tw({
                                                        url,
                                                        title: address,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={twitterIcon}
                                                    alt="twitter"
                                                    height="45"
                                                    width="45"
                                                    className="mx-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    whatsapp({
                                                        url,
                                                        title: address,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={whatsappIcon}
                                                    alt="whatsapp"
                                                    height="45"
                                                    width="45"
                                                    className="mx-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    linkedin({
                                                        url,
                                                        address,
                                                        description,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={linkedinIcon}
                                                    alt="linkedin"
                                                    height="45"
                                                    width="45"
                                                    className="mx-2"
                                                />
                                            </a>
                                            <a
                                                className="rounded-none border-0 font-medium"
                                                onClick={() =>
                                                    pinterest({
                                                        url,
                                                        description,
                                                        media: objects[0].url,
                                                    })
                                                }
                                            >
                                                <img
                                                    src={pinterestIcon}
                                                    alt="pinterest"
                                                    height="45"
                                                    width="45"
                                                    className="ml-2"
                                                />
                                            </a>
                                        </div>
                                    }
                                >
                                    <Button
                                        type="text"
                                        icon={<FA icon={faShareSquare} className="mr-2 text-lg sm:text-xl" />}
                                        // eslint-disable-next-line max-len
                                        className="px-0 text-lg font-light text-gray-600 hover:bg-transparent hover:text-primary-500 sm:text-base md:text-lg"
                                    >
                                        <span className="text-base text-[#313741]">Share</span>
                                    </Button>
                                </Popover>
                            </div>
                            {/*  */}
                            <HideListing
                                // handleClickChange={handleClickChange}
                                hideStatus="unhide"
                                propertyId={id}
                                propertySearchVariables={propertySearchVariables}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FeaturedPropertyCard.propTypes = {
    collectionId: PropTypes.string,
    isFeed: PropTypes.bool,
    ofiTime: PropTypes.bool,
    isInfoBox: PropTypes.bool,
    property: PropTypes.shape({
        id: PropTypes.string,
        slugUrl: PropTypes.string,
        features: PropTypes.object,
        priceView: PropTypes.string,
        isUnderOffer: PropTypes.bool,
        status: PropTypes.string,
        agents: PropTypes.array,
        agency: PropTypes.object,
    }),
    isFromSearch: PropTypes.bool,
    carouselImageHeight: PropTypes.string,
    sr: PropTypes.string,
    hasScrolled: PropTypes.bool,
    isHomePage: PropTypes.bool,
    showCardActions: PropTypes.bool,
    offsetHeaderHeight: PropTypes.number,
    isPropertyPage: PropTypes.bool,
    searchOptions: PropTypes.string,
    searchUrl: PropTypes.string,
    propertySearchVariables: PropTypes.object,
};

FeaturedPropertyCard.defaultProps = {
    collectionId: '',
    isFeed: false,
    ofiTime: false,
    isInfoBox: false,
    property: {},
    isFromSearch: false,
    carouselImageHeight: '16rem',
    sr: '',
    hasScrolled: false,
    isHomePage: false,
    showCardActions: true,
    offsetHeaderHeight: 0,
    isPropertyPage: false,
    searchOptions: '',
    searchUrl: '',
    propertySearchVariables: {},
};
export default FeaturedPropertyCard;
